import React, {useEffect, useState} from "react";
import {AUTH_TOKEN, USER_ID} from "../SharingConstants";
import {useNavigate, useParams} from 'react-router-dom';

export default function DownloadFile() {
    let navigate = useNavigate();
    const [showLoader, setShowLoader] = React.useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
    const { file_id } = useParams();
    useEffect(() => {
        console.log(file_id);
           downloadFile(file_id);
    }, []);

    async function downloadFile(fileId) {
        const baseUrl = window.location.origin;
        const url = "/file_download"
        const body = {
            user_id: localStorage.getItem(USER_ID),
            unique_id: fileId
        };
        setShowLoader(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(baseUrl + url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
            body: JSON.stringify(body)
        })
        setShowLoader(false);
        result = await result.json();
        if (result.errors === 'User ID missing in token.') {
            localStorage.clear();
            navigate('/', {replace: false})
        }
        if (result.success) {
            window.open(result.data)
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }
}
