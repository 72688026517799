import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './Dashboard.css';
import {AUTH_STATUS, USER_ROLE} from "../SharingConstants";
import {Tab, Tabs} from "@mui/material";
import SharingRequest from "../sharing_request/SharingRequest";
import SharingAction from "../sharing_action/SharingAction";
import SharingWith from "../sharing_with/SharingWith";

export default function Dashboard() {
    const [tabValue, setTabValue] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        let authStatus= JSON.parse(localStorage.getItem(AUTH_STATUS));
        if(authStatus) {
            if(localStorage.getItem(USER_ROLE) === 'admin') {
                navigate('/dashboard/sharing_module')
            }
        } else {
            navigate('/', { replace: false })
        }
    })

    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const typeOfRequest = () => {
        if (tabValue === 0) {
            return (
                <SharingRequest></SharingRequest>
            )
        }
        if (tabValue === 1) {
            return (
                <SharingAction></SharingAction>
            )
        }
        if (tabValue === 2) {
            return (
                <SharingWith></SharingWith>
            )
        }
    }

    return (
        <div className='dashboard-div'>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            <div className='dashboard-main-div'>
                <Tabs value={tabValue}
                      onChange={tabChange}
                      TabIndicatorProps={{
                          style: {
                              background: '#FF9559',
                              height: 5,
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5
                          }
                      }}
                      sx={{
                          "&& .Mui-selected": { // && are used to increase the specificity
                              color: "#FF9559"
                          }
                      }}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable force tabs example">
                    <Tab label="Files Requested"
                         value={0}
                         style={styles.tab}
                    />
                    <Tab label="Approvals"
                         value={1}
                         style={styles.tab}
                    />
                    <Tab label="Overseers"
                         value={2}
                         style={styles.tab}
                    />
                </Tabs>
                <div className='dashboard-row-1-div'>
                    {typeOfRequest()}
                </div>
            </div>
        </div>
    )
}

const styles = {
    tab: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        textTransform: 'none',
        marginLeft: 10
    }
}