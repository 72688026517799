import React, {useEffect, useState} from "react";
import '../sharing_action/SharingAction.css';
import '../sharing_with/SharingWith.css'
import SaralShare from "../../../assets/icons/saral-share.svg";
import {Alert, IconButton, Snackbar, Tab, Tabs, Toolbar, Tooltip} from "@mui/material";
import {AUTH_STATUS, AUTH_TOKEN, USER_ID, USER_ROLE} from "../SharingConstants";
import Loader from "../loader/Loader";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function SharingAction() {
    const [tabValue, setTabValue] = useState(0);
    const [actionUseEffectFlag, setActionUseEffectFlag] = useState(true);
    const [showLoader, setShowLoader] = React.useState(false);
    const [approveDeclineModalData, setApproveDeclineModalData] = useState();
    const [sharingActionDetails, setSharingActionDetails] = useState([]);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
    const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');

    useEffect(() => {
        const authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
        const role = localStorage.getItem(USER_ROLE);
        if (!authStatus) {
            navigate('/', {replace: false})
        }
        if(role !== 'user') {
            navigate('/dashboard', { replace: false })
        }
    }, []);

    useEffect(() => {
        {
            actionUseEffectFlag && allRequest(tabValue)
        }
    }, [actionUseEffectFlag]);

    async function allRequest(type) {
        const url = "sharing_with";
        const body = {
            user_id: localStorage.getItem(USER_ID),
            type: type
        };
        setShowLoader(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
            body: JSON.stringify(body)
        })
        setShowLoader(false);
        result = await result.json();
        if(result.errors === 'User ID missing in token.') {
            localStorage.clear();
            navigate('/', {replace: false})
        }
        if (result.success) {
            setActionUseEffectFlag(false);
            setSharingActionDetails(result.data);
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }

    const tabChange = (event, newValue) => {
        setTabValue(newValue);
        allRequest(newValue).then();
    };

    const pending = () => {
        return (
            <>
                {sharingActionDetails && sharingActionDetails.length > 0 && sharingActionDetails.map(data => (
                    <>
                        {data.status === "pending" ?
                            (
                                <div className="action-box-pending-div">
                                    <div className="action-box-share-with-pending-detail-div" key={data}>
                                        <div className="action-box-pending-heading-div">
                                            <div className="pending-box-detail-div" key={data}>
                                                <span className="pending-span-black">{data.uploaded_by + "  has requested approval for -  "}<span className="pending-span-blue">{data.note_title}</span></span>
                                            </div>
                                            <div className="pending-box-arrow-div">
                                                <Toolbar>
                                                    <IconButton
                                                        aria-label="ArrowForwardIosRounded"
                                                        onClick={() => setApproveDeclineModalData(data)}
                                                    >
                                                        <ArrowForwardIosRoundedIcon fontSize="inherit"/>
                                                    </IconButton>
                                                </Toolbar>
                                            </div>
                                        </div>
                                        <div className="share-with-pending-button-div">
                                            <div className="share-with-pending-status-div">
                                                <span className="action-approved-status-span">Pending</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : <></>
                        }
                    </>
                ))}
            </>
        )
    }

    const approved = () => {
        return (
            <>
                {sharingActionDetails && sharingActionDetails.length > 0 && sharingActionDetails.map(data => (
                    <>
                        {data.status === "approved" ?
                            (
                                <div className="action-box-approve-div">
                                    <div className="action-box-approve-detail-div" key={data}>
                                        <div className="action-box-approve-heading-div">
                                            <div className="approve-box-detail-div" key={data}>
                                                <span className="approve-span-black">{data.uploaded_by + "  had requested approval for -  "}<span className="approve-span-blue">{data.note_title}</span></span>
                                            </div>
                                            <div className="approve-box-arrow-div">
                                                {data.status === "approved" ?
                                                    <Toolbar>
                                                        <IconButton
                                                            aria-label="ArrowForwardIosRounded"
                                                            onClick={() => setApproveDeclineModalData(data)}
                                                        >
                                                            <ArrowForwardIosRoundedIcon fontSize="inherit"/>
                                                        </IconButton>
                                                    </Toolbar> : <Toolbar></Toolbar>
                                                }
                                            </div>
                                        </div>
                                        <div className="action-box-approve-button-div">
                                            <div className="action-approved-status-div">
                                                <span className="action-approved-status-span">Approved</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : <></>
                        }
                    </>
                ))}
            </>
        )
    }

    const rejected = () => {
        return (
            <>
                {sharingActionDetails && sharingActionDetails.length > 0 && sharingActionDetails.map(data => (
                    <>
                        {data.status === "declined" ?
                            (
                                <div className="action-box-decline-div">
                                    <div className="action-box-decline-detail-div" key={data}>
                                        <div className="action-box-decline-heading-div">
                                            <div className="decline-box-detail-div" key={data}>
                                                <span className="decline-span-black">{data.uploaded_by + "  had requested approval for -  "}<span className="decline-span-blue">{data.note_title}</span></span>
                                            </div>
                                            <div className="decline-box-arrow-div">
                                                {data.status === "declined" ?
                                                    <Toolbar>
                                                        <IconButton
                                                            aria-label="ArrowForwardIosRounded"
                                                            onClick={() => setApproveDeclineModalData(data)}
                                                        >
                                                            <ArrowForwardIosRoundedIcon fontSize="inherit"/>
                                                        </IconButton>
                                                    </Toolbar> : <Toolbar></Toolbar>
                                                }
                                            </div>
                                        </div>
                                        <div className="action-box-decline-button-div">
                                            <div className="action-declined-status-div">
                                                <span className="action-declined-status-span">Rejected</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : <></>
                        }
                    </>
                ))}
            </>
        )
    }

    const approvalRequestedFileDetail = (data) => {
        if (data) {
            return (
                <div className="approve-decline-modal-background-div">
                    <div className={data.status === 'pending' ? "approve-decline-pending-modal-div" : "approve-decline-modal-div"}>
                        <div className="approve-decline-modal-close">
                            <Toolbar>
                                <IconButton
                                    aria-label="CloseRoundedIcon"
                                    onClick={() => setApproveDeclineModalData('')}
                                >
                                    <CloseRoundedIcon fontSize="inherit"/>
                                </IconButton>
                            </Toolbar>
                        </div>
                        {   data.status === 'pending' ?
                            <div className="approve-decline-modal-detail-div">
                                <Tooltip title={data.uploaded_by + "  has request approval for - " + data.note_title}
                                         arrow>
                                      <span
                                          className="approve-decline-modal-detail-span-black">{data.uploaded_by + "  has request approval for -  "}<span
                                          className="approve-decline-modal-detail-span-blue">{data.note_title}</span></span>
                                </Tooltip>
                            </div> : <></>
                        }
                        <div className="approve-decline-modal-bottom-div">
                            <span className="file-name-span">File Name - {data.file_name}</span>
                            <span className="note-title-span">Note - {data.note_title}</span>
                            <span className="request-label-span">Request sent at - </span>
                            <span className="request-value-span">{data.sent_at}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const typeOfRequest = () => {
        if (tabValue === 0) {
            return (
                pending()
            )
        }

        if (tabValue === 1) {
            return (
                approved()
            )
        }

        if (tabValue === 2) {
            return (
                rejected()
            )
        }
    }

    function snackbarError(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarErrorMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setSnackbarErrorMessage('')} severity="warning" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    function snackbarSuccess(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarSuccessMessage('')}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={() => setSnackbarSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    return (
        <div className='sharing-action-div'>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            {showLoader ? <Loader/> : false}
            {approvalRequestedFileDetail(approveDeclineModalData)}
            <div className='sharing-action-main-div'>
                {snackbarError(snackbarErrorMessage)}
                {snackbarSuccess(snackbarSuccessMessage)}
                <div className='sharing-action-row-1-div'>
                    <div className='sharing-action-heading-div'>
                        <img src={`${SaralShare}`} className="sharing-action-heading-icon" alt="SaralShare"/>
                        <span className='sharing-action-heading-span'>Saral Share</span>
                    </div>
                </div>

                <div className='sharing-action-row-2-div'>
                    <div className='sharing-action-row-2-background-div'>
                        <Tabs value={tabValue}
                              onChange={tabChange}
                              TabIndicatorProps={{
                                  style: {
                                      background: '#FF9559',
                                      height: 5,
                                      borderTopLeftRadius: 5,
                                      borderTopRightRadius: 5
                                  }
                              }}
                              sx={{
                                  "&& .Mui-selected": { // && are used to increase the specificity
                                      color: "#FF9559"
                                  }
                              }}
                              variant="scrollable"
                              scrollButtons
                              allowScrollButtonsMobile
                              aria-label="scrollable force tabs example">
                            <Tab label="Pending"
                                 value={0}
                                 style={styles.tab}
                            />
                            <Tab label="Approved"
                                 value={1}
                                 style={styles.tab}
                            />
                            <Tab label="Rejected"
                                 value={2}
                                 style={styles.tab}
                            />
                        </Tabs>
                    </div>
                </div>

                <div className='sharing-action-row-3-div'>
                    {typeOfRequest()}
                </div>
            </div>
        </div>
    )
}

const styles = {
    tab: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        textTransform: 'none',
        marginLeft: 10
    }
}