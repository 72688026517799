import React, {useEffect, useRef, useState} from "react";
import './Home.css';
import {Alert, Snackbar, TextField} from "@mui/material";
import * as Constant from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import {AUTH_STATUS} from "../SharingConstants";
import Loader from "../loader/Loader";
import BJPLogo from "../../../assets/logos/BJP-logo.svg";

export default function Home() {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState();
    const [otp, setOTP] = useState();
    const [otpToken, setOTPToken] = useState();
    const [isOTPEnable, setOTPEnable] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
    const [showLoader, setShowLoader] = React.useState(false);
    const textInput = useRef();

    useEffect(() => {
        let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
        if(authStatus) {
            navigate('/dashboard', {replace: false})
        }
    })

    useEffect(() => {
        if (otp !== undefined) {
            setButtonDisable(true)
            if (otp.toString().length !== 6) {
                setError(false);
                setErrorMessage('');
            } else {
                setError(false);
                setErrorMessage('');
                setButtonDisable(false);
            }
        }
    }, [otp]);

    useEffect(() => {
        if (phoneNumber !== undefined) {
            setButtonDisable(true)
            if (phoneNumber && !(/^[5-9]$/).test(phoneNumber.toString().charAt(0))) {
                setError(true);
                setErrorMessage('Please enter a valid phone number');
            } else if (phoneNumber.toString().length !== 10) {
                setError(false);
                setErrorMessage('');
            } else {
                setError(false);
                setErrorMessage('');
                setButtonDisable(false);
            }
        }
    }, [phoneNumber]);

    async function requestOTP(event) {
        event.preventDefault();
        const url = "login";
        const body = {
            phone_number: phoneNumber
        };
        setShowLoader(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        setShowLoader(false);
        result = await result.json();
        if (result.success) {
            setOTPEnable(true)
            setButtonDisable(true);
            setOTPToken(result.token);
            textInput.current.value = '';
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }

    async function login(event) {
        event.preventDefault();
        const url = "submit_otp";
        const body = {
            identification_token: otpToken,
            otp: otp
        };
        setShowLoader(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        setShowLoader(false);
        result = await result.json();
        if (result.success) {
            loginSuccessful(result)
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }

    function loginSuccessful(loginData) {
        localStorage.setItem(Constant.AUTH_STATUS, true);
        localStorage.setItem(Constant.AUTH_TOKEN, loginData.auth_token);
        localStorage.setItem(Constant.USER_ID, loginData.user.id);
        localStorage.setItem(Constant.USER_ROLE, loginData.user.role);
        localStorage.setItem(Constant.USER_NAME, loginData.user.name);
        localStorage.setItem(Constant.USER_EMAIL, loginData.user.email);
        localStorage.setItem(Constant.USER_PHONE, loginData.user.phone_number);
        navigate('/dashboard', {replace: false});

    }

    function validPhoneNumber() {
        setError(false);
        setErrorMessage('');
        if (phoneNumber !== undefined) {
            if (phoneNumber.toString().length !== 10) {
                setError(true);
                setErrorMessage('Please enter a valid phone number');
            } else {
                setError(false);
                setErrorMessage('');
            }
        }
        return error;
    }

    function validOTP() {
        setError(false);
        setErrorMessage('');
        if (otp !== undefined) {
            if (otp.toString().length !== 6) {
                setError(true);
                setErrorMessage('Please enter a valid otp');
            } else {
                setError(false);
                setErrorMessage('');
            }
        }
        return error;
    }

    function snackbarError(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarErrorMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setSnackbarErrorMessage('')} severity="warning" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    return (
        <div className='home-div'>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            {showLoader ? <Loader/> : false}
            <div className='home-main-div'>
                <div className='login-header-div'>
                    <img src={`${BJPLogo}`} className='home-bjp-logo' alt="BJP"/>
                    <span className='home-bjp-span'>भारतीय जनता पार्टी</span>
                    <span className='home-app-name-span'>Saral Sharing</span>
                </div>
                {snackbarError(snackbarErrorMessage)}
                <form>
                    {!isOTPEnable ? (
                        <div className='form-group'>
                            <TextField
                                className='login-input-field'
                                id="phoneNumber"
                                type="number"
                                name="phoneNumber"
                                label="Phone Number"
                                inputMode="decimal"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 10))
                                }}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onBlur={validPhoneNumber}
                                helperText={errorMessage}
                                error={error}
                                required
                                inputProps={{style: {fontSize: 20}}}
                                InputLabelProps={{style: {fontSize: 20}}}
                                variant={"filled"}
                            />
                        </div>
                    ) : (
                        <div className='form-group'>
                            <span></span>
                            <TextField
                                className='login-input-field'
                                id="otp"
                                type="number"
                                name="otp"
                                label="OTP"
                                inputMode="decimal"
                                autoFocus={true}
                                inputRef={textInput}
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 6))
                                }}
                                onChange={(e) => setOTP(e.target.value)}
                                onBlur={validOTP}
                                helperText={errorMessage}
                                error={error}
                                required
                                inputProps={{style: {fontSize: 20}}}
                                InputLabelProps={{style: {fontSize: 20}}}
                                variant={"filled"}
                            />
                        </div>
                    )}
                    <div className="form-button">
                        <button disabled={buttonDisable} type="submit" className='btn login-button custom-button mt-3'
                                onClick={!isOTPEnable ? requestOTP : login}>
                            {!isOTPEnable ? 'Request OTP' : 'Login'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}