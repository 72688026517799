import React from "react";
import './Loader.css';
import {CircularProgress, circularProgressClasses} from "@mui/material";

export default function Loader() {
    return (
        <div className='modal-background-div'>
            <div className='loader-div'>
                <CircularProgress
                    sx={{
                        color: '#000000',
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={100}
                    thickness={5}
                />
            </div>
        </div>
    )
}