import React, {useEffect, useState} from "react";
import './SharingHistory.css';
import Loader from "../loader/Loader";
import SharingHistoryIcon from "../../../assets/icons/sharing_history.svg";
import ApproverIcon from "../../../assets/icons/approver.svg";
import ReceiverIcon from "../../../assets/icons/receiver.svg";
import OverseerIcon from "../../../assets/icons/Overseers.svg"
import {Alert, Breadcrumbs, Link, Snackbar, Tab, Tabs, Typography, Grid} from "@mui/material";
import {AUTH_STATUS, AUTH_TOKEN, USER_ID, USER_ROLE} from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function SharingHistory() {
    let navigate = useNavigate();
    const [historyUseEffectFlag, setHistoryUseEffectFlag] = useState(true);
    const [sharingHistoryDetails, setSharingHistoryDetails] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [showLoader, setShowLoader] = React.useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
    const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');

    useEffect(() => {
        const authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
        const role = localStorage.getItem(USER_ROLE);
        if (!authStatus) {
            navigate('/', {replace: false})
        }
        if(role !== 'admin') {
            navigate('/dashboard', { replace: false })
        }
    }, []);

    useEffect(() => {
        {
            historyUseEffectFlag && sharingHistoryList(event, tabValue)
        }
    }, [historyUseEffectFlag]);

    function handleClick(event) {
        event.preventDefault();
        const url = event.currentTarget.href;
        window.location.href = url;
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/dashboard" onClick={handleClick}>
            SARAL
        </Link>,
        <Link underline="hover" key="2" color="inherit" href="/dashboard/sharing_module" onClick={handleClick}>
            Sharing Module
        </Link>,
        <Typography key="2" color="#366FB2" className='sharing-history-breadcrumb-typography'>
            Sharing History
        </Typography>,
    ];

    const tabChange = (event, newValue) => {
        setTabValue(newValue);
        sharingHistoryList(event, newValue).then();
    };

    async function sharingHistoryList(event, type) {
        event.preventDefault();
        const url = "all_sharing_history";
        const body = {
            user_id: localStorage.getItem(USER_ID),
            type: type
        };
        setShowLoader(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
            body: JSON.stringify(body)
        })
        setShowLoader(false);
        result = await result.json();
        if(result.errors === 'User ID missing in token.') {
            localStorage.clear();
            navigate('/', {replace: false})
        }
        if(result.success) {
            setShowLoader(false);
            setHistoryUseEffectFlag(false);
            setSharingHistoryDetails(result.data);
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }

    const sharingHistoryListDiv = () => {
        return (
            <>
                {sharingHistoryDetails && sharingHistoryDetails.length > 0 && sharingHistoryDetails.map((data, index) => (
                    <div className="sharing-history-list-div" key={data}>
                        <div className="sharing-history-list-col-2-tag-mobile-div">
                            <div className={data.type !== 'Direct' ? (data.type === "Approved" ? "sharing-history-list-col-2-tag-approved-status-div" : data.type === "Pending" ? "sharing-history-list-col-2-tag-pending-status-div" : "sharing-history-list-col-2-tag-declined-status-div") : ("sharing-history-list-col-2-tag-null-status-div")}>
                                <span className="sharing-history-list-col-2-tag-status-span">{data.type !== 'Direct' ? ('Sharing Status- ' + data.type) : ('')}</span>
                            </div>
                            <div className="sharing-history-list-col-2-tag-type-div">
                                <span className="sharing-history-list-col-2-tag-type-span">{data.type !== 'Direct' ? 'Sharing Type- Share with approval' : 'Sharing Type- Direct share'}</span>
                            </div>
                        </div>
                        <div className="sharing-history-list-col-1-div">
                            <div className="sharing-history-list-col-1-row-1-div">
                                <span className="sharing-history-list-col-1-row-1-span">{index+1 +'.   '+ data.file_title}</span>
                            </div>

                            <div className="sharing-history-list-col-1-row-2-div">
                                <span className="sharing-history-list-col-1-row-1-file-name-span">{'File Name- ' + data.file_name}</span>
                                <span className="sharing-history-list-col-1-row-1-file-size-span">{'File Size- ' + data.file_size}</span>
                                <span className="sharing-history-list-col-1-row-1-file-type-span">{'File Type- ' + data.file_type}</span>
                            </div>

                            <div className="sharing-history-list-col-1-row-3-div">
                                <span className="sharing-history-list-col-1-row-1-file-sent-label-span">Sent on-</span>
                                <span className="sharing-history-list-col-1-row-1-file-sent-value-span">{data.sent_at}</span>
                            </div>
                        </div>

                        <div className="sharing-history-list-col-2-div">
                            <div className="sharing-history-list-col-2-tag-desktop-div">
                                <div className={data.type !== 'Direct' ? (data.type === "Approved" ? "sharing-history-list-col-2-tag-approved-status-div" : data.type === "Pending" ? "sharing-history-list-col-2-tag-pending-status-div" : "sharing-history-list-col-2-tag-declined-status-div") : ("sharing-history-list-col-2-tag-null-status-div")}>
                                    <span className="sharing-history-list-col-2-tag-status-span">{data.type !== 'Direct' ? ('Sharing Status- ' + data.type) : ('')}</span>
                                </div>
                                <div className="sharing-history-list-col-2-tag-type-div">
                                    <span className="sharing-history-list-col-2-tag-type-span">{data.type !== 'Direct' ? 'Sharing Type- Share with approval' : 'Sharing Type- Direct share'}</span>
                                </div>
                            </div>
                            <div className="sharing-history-list-col-2-row-1-div">
                                <div className="sharing-history-list-col-2-row-1-receiver-detail-div">
                                    <div className="sharing-history-list-col-2-row-1-receiver-detail-1-div">
                                        <img src={`${ReceiverIcon}`} className="sharing-history-receiver-icon" alt="SaralShare"/>
                                        <span className="sharing-history-receiver-name-label-span">Receiver's Name- <span className="sharing-history-receiver-name-value-span">{data.r_name}</span></span>
                                    </div>
                                    <span className="sharing-history-receiver-phone-span">{'Phone- ' + data.r_phone_number}</span>
                                </div>

                                <div className="sharing-history-list-col-2-row-1-divider-div"></div>

                                <div className="sharing-history-list-col-2-row-1-receiver-note-div">
                                    <span className="sharing-history-receiver-note-label-span">Note- <span className="sharing-history-receiver-note-value-span">{data.r_note}</span></span>
                                </div>
                            </div>

                            <div className={data.type !== 'Direct' ? "sharing-history-list-col-2-row-2-div" : "hide-div"}>
                                <div className="sharing-history-list-col-2-row-1-approver-detail-div">
                                    <div className="sharing-history-list-col-2-row-1-approver-detail-1-div">
                                        <img src={`${ApproverIcon}`} className="sharing-history-approver-icon" alt="SaralShare"/>
                                        <span className="sharing-history-approver-name-label-span">Approver's Name- <span className="sharing-history-approver-name-value-span">{data.a_name}</span></span>
                                    </div>
                                    <span className="sharing-history-approver-phone-span">{'Phone- ' + data.a_phone_number}</span>
                                </div>

                                <div className="sharing-history-list-col-2-row-1-divider-div"></div>

                                <div className="sharing-history-list-col-2-row-1-approver-note-div">
                                    <span className="sharing-history-approver-note-label-span">Note- <span className="sharing-history-approver-note-value-span">{data.a_note}</span></span>
                                </div>
                            </div>
                            {
                                data.overseers?.length !== 0 &&
                                <div
                                    className={data.type !== 'Direct' ? "sharing-history-list-col-2-row-2-div" : "hide-div"}>
                                    <div className="sharing-history-list-col-2-row-1-approver-detail-div">
                                        <div className="sharing-history-list-col-2-row-1-approver-detail-1-div">
                                            <img src={`${OverseerIcon}`} className="sharing-history-approver-icon"
                                                 alt="SaralShare"/>
                                            <span
                                                className="sharing-history-approver-name-label-span">Overseers -</span>
                                        </div>
                                    </div>
                                    <div className="sharing-history-list-col-2-row-1-divider-div"></div>
                                    <Grid container spacing={3} >
                                        {data.overseers?.map((item, i) => {
                                            return (<Grid item xs={6} sm={6} md={6} lg={4}  key={i} className="overseers-history-display-name">
                                                    <p>{i + 1}.</p>
                                                    <p>{item[1]}</p>
                                                    <p>|</p>
                                                    <p>{item[0]}</p>
                                            </Grid>);
                                        })}
                                    </Grid>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </>
        )
    }

    function snackbarError(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarErrorMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setSnackbarErrorMessage('')} severity="warning" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    function snackbarSuccess(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarSuccessMessage('')}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={() => setSnackbarSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    return(
        <div className='sharing-history-div'>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            {showLoader ? <Loader /> : false}
            <div className='sharing-history-main-div'>
                {snackbarError(snackbarErrorMessage)}
                {snackbarSuccess(snackbarSuccessMessage)}
                <div className='sharing-history-breadcrumb-div'>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                                 aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
                <div className='sharing-history-row-1-div'>
                    <div className='sharing-history-heading-div'>
                        <img src={`${SharingHistoryIcon}`} className="sharing-history-heading-icon" alt="SaralShare"/>
                        <span className='sharing-history-heading-span'>Sharing History</span>
                    </div>
                </div>

                <div className='sharing-history-row-2-div'>
                    <div className="sharing-history-type-div">
                        <Tabs value={tabValue}
                              onChange={tabChange}
                              TabIndicatorProps={{
                                  style: {
                                      display: "none"
                                  }
                              }}
                              sx={{
                                  "&& .Mui-selected": { // && are used to increase the specificity
                                      color: "#FFFFFF",
                                      background: "#FF9559",
                                      borderRadius: 10
                                  }
                              }}
                              aria-label="basic tabs example">
                            <Tab label="All"
                                 value={0}
                                 style={styles.tab}
                            />
                            <Tab label="Approved"
                                 value={1}
                                 style={styles.tab}
                            />
                            <Tab label="Pending"
                                 value={2}
                                 style={styles.tab}
                            />
                            <Tab label="Declined"
                                 value={3}
                                 style={styles.tab}
                            />
                        </Tabs>
                    </div>
                </div>

                <div className='sharing-history-row-3-div'>
                    {sharingHistoryListDiv()}
                </div>
            </div>
        </div>
    )
}

const styles = {
    tab: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        textTransform: 'none',
        marginLeft: 10
    }
}