import React, {useEffect, useState} from "react";
import './SharingRequest.css';
import SaralShare from "../../../assets/icons/saral-share.svg"
import ActiveDownload from "../../../assets/icons/active_download.svg"
import ExpiredDownload from "../../../assets/icons/expired_download.svg"
import USER from "../../../assets/icons/user.svg"
import {
    Alert,
    CircularProgress,
    circularProgressClasses,
    Snackbar,
    Tab,
    Tabs,
    Toolbar, Tooltip
} from "@mui/material";
import {AUTH_STATUS, AUTH_TOKEN, USER_ID, USER_ROLE} from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import Loader from "../loader/Loader";

export default function SharingRequest() {
    let navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [requestUseEffectFlag, setRequestUseEffectFlag] = useState(true);
    const [sharingRequestDetails, setSharingRequestDetails] = useState([]);
    const [showLoader, setShowLoader] = React.useState(false);
    const [approveDeclineModalData, setApproveDeclineModalData] = useState();
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
    const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');
    const expiredInHours = 48;
    const date = new Date();

    useEffect(() => {
        const authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
        const role = localStorage.getItem(USER_ROLE);
        if (!authStatus) {
            navigate('/', {replace: false})
        }
        if(role !== 'user') {
            navigate('/dashboard', { replace: false })
        }
    }, []);

    useEffect(() => {
        {
            requestUseEffectFlag && allRequest(tabValue)
        }
    }, [requestUseEffectFlag]);

    async function allRequest(type) {
        const url = "all_sharing_request";
        const body = {
            user_id: localStorage.getItem(USER_ID),
            type: type
        };
        setShowLoader(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
            body: JSON.stringify(body)
        })
        setShowLoader(false);
        result = await result.json();
        if(result.errors === 'User ID missing in token.') {
            localStorage.clear();
            navigate('/', {replace: false})
        }
        if (result.success) {
            setRequestUseEffectFlag(false);
            setSharingRequestDetails(result.data);
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }

    const tabChange = (event, newValue) => {
        setTabValue(newValue);
        allRequest(newValue).then();
    };

    const styles = {
        tab: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 20,
            textTransform: 'none',
            marginLeft: 10
        }
    }

    async function downloadFile(fileId) {
        const url = "file_download";
        const body = {
            user_id: localStorage.getItem(USER_ID),
            file_id: fileId
        };
        setShowLoader(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
            body: JSON.stringify(body)
        })
        setShowLoader(false);
        result = await result.json();
        if(result.errors === 'User ID missing in token.') {
            localStorage.clear();
            navigate('/', {replace: false})
        }
        if (result.success) {
            window.open(result.data)
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }

    const getTimeDifferences = (created_at) => {
        const expireDate = new Date(created_at);
        return Math.floor(Math.abs((+expireDate) - (+date)) / (1000 * 60 * 60));
    }

    const getRemainingTime = (created_at) => {
        return (Math.floor(getTimeDifferences(created_at)) * (100 / expiredInHours));
    }

    const fileExpired = (created_at) => {
        if((Math.floor(expiredInHours - getTimeDifferences(created_at))) > 0 && (Math.floor(expiredInHours - getTimeDifferences(created_at))) <= expiredInHours) {
            return false;
        }
        return true;
    }

    const typeOfRequest = () => {
        if (tabValue === 0) {
            return (
                <>
                    {sharingRequestDetails && sharingRequestDetails.length > 0 && sharingRequestDetails.map(data => (
                        <>
                            <>
                                {data.status === "pending" && data.type === "download" ? (
                                    <div className="request-box-download-div">
                                        <div className="request-box-detail-div" key={data}>
                                            <div className="text-1-div">
                                                <span
                                                    className="text-1-span">{"Approval Pending with " + (data.authenticator_name ? data.authenticator_name : '-')}</span>
                                            </div>
                                            <div className="text-2-div">
                                                <span
                                                    className="text-2-span">{"Once approved you will be able to download"}</span>
                                            </div>
                                            {
                                                data.overseers.length !== 0 &&
                                                <div className="overseers-download-div">
                                                    <div className="overseers-download-label">
                                                        <img src={`${USER}`} className="user-icon"
                                                             alt="SaralShare"/>
                                                        <span className="overseer-name-label-span">Overseers -</span>
                                                    </div>
                                                    {data.overseers.map((item, i) => {
                                                        return (<div key={i}
                                                                     className="overseers-download-fields">
                                                            <p>{i + 1}.</p>
                                                            <p>{item[1]}</p>
                                                            <p>|</p>
                                                            <p>{item[0]}</p>
                                                        </div>);
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        <button className="inactive-download-button"
                                                disabled={true}
                                                onClick={() => downloadFile(data.file_id)}
                                        >
                                            <img src={`${ExpiredDownload}`} className="download_type-icon"
                                                 alt="SaralShare"/>
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        {data.status === "declined" ?
                                            (
                                                <div className="request-box-decline-div">
                                                    <div className="request-box-decline-detail-div" key={data}>
                                                        <div className="request-box-decline-heading-div">
                                                            <div className="decline-box-detail-div" key={data}>
                                                                <span className="decline-box-detail-span-red">{data.authenticator_name + "  has declined for approval of - " + data.note_title}</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            data.overseers.length !== 0 &&
                                                            <div className="overseers-download-div">
                                                                <div className="overseers-download-label">
                                                                    <img src={`${USER}`} className="user-icon"
                                                                         alt="SaralShare"/>
                                                                    <span className="overseer-name-label-span">Overseers -</span>
                                                                </div>
                                                                {data.overseers.map((item, i) => {
                                                                    return (<div key={i}
                                                                                 className="overseers-download-fields">
                                                                        <p>{i + 1}.</p>
                                                                        <p>{item[1]}</p>
                                                                        <p>|</p>
                                                                        <p>{item[0]}</p>
                                                                    </div>);
                                                                })}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ) : (null)
                                        }
                                    </>
                                )}
                            </>
                            {data.status === "declined" || data.status === "pending" ?
                                (null) : (
                                    <div className="request-box-download-div">
                                        <div className="request-box-detail-div" key={data}>
                                            {!data.is_private || data.status === "approved" ? (
                                                <>
                                                    <div className="file-heading-div">
                                                        <span className="file-heading-span">{data.note_title}</span>
                                                    </div>
                                                    <div className="remaining-time-div">
                                                        <CircularProgress
                                                            variant="determinate"
                                                            disableShrink
                                                            sx={{
                                                                color: '#FF7576',
                                                                [`& .${circularProgressClasses.circle}`]: {
                                                                    strokeLinecap: 'round',
                                                                },
                                                            }}
                                                            size={18}
                                                            thickness={5}
                                                            value={fileExpired(data.created_at) ? 100 : getRemainingTime(data.created_at)}
                                                        />
                                                        <span
                                                            className="remaining-time-span">{fileExpired(data.created_at) ? 'Expired' : expiredInHours - getTimeDifferences(data.created_at) + ' Hour(s) Remaining'}</span>
                                                    </div>
                                                    {data.status === "approved" ? (
                                                        <>
                                                            <div className="authenticator-detail-div">
                                                                <img src={`${USER}`} className="user-icon"
                                                                     alt="SaralShare"/>
                                                                <div className="authenticator-name-div">
                                                                    <span className="authenticator-name-label-span">Approved By -</span>
                                                                    <span
                                                                        className="authenticator-name-span">{data.authenticator_name ? data.authenticator_name : '-'}</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                data.overseers.length !== 0 &&
                                                                <div className="overseers-download-div">
                                                                    <div className="overseers-download-label">
                                                                        <img src={`${USER}`} className="user-icon"
                                                                             alt="SaralShare"/>
                                                                        <span className="overseer-name-label-span">Overseers -</span>
                                                                    </div>
                                                                    {data.overseers.map((item, i) => {
                                                                        return (<div key={i}
                                                                                     className="overseers-download-fields">
                                                                            <p>{i + 1}.</p>
                                                                            <p>{item[1]}</p>
                                                                            <p>|</p>
                                                                            <p>{item[0]}</p>
                                                                        </div>);
                                                                    })}
                                                                </div>
                                                            }
                                                        </>
                                                    ) : (null)}
                                                </>
                                            ) : (null)}
                                        </div>
                                        <button
                                            className={data.status === "pending" ? null : fileExpired(data.created_at) ? "expired-download-button" : "active-download-button"}
                                            disabled={fileExpired(data.created_at) ? true : data.status === "pending"}
                                            onClick={() => downloadFile(data.file_id)}
                                        >
                                            <img
                                                src={fileExpired(data.created_at) ? `${ExpiredDownload}` : `${ActiveDownload}`}
                                                className="download_type-icon" alt="SaralShare"/>
                                        </button>
                                    </div>
                                )
                            }
                        </>
                    ))}
                </>
            )
        }

        if (tabValue === 1) {
            return (
                <>
                    {sharingRequestDetails && sharingRequestDetails.length > 0 && sharingRequestDetails.map(data => (
                        <div className="request-box-download-div">
                            <div className="request-box-detail-div" key={data}>
                                <div className="text-1-div">
                                    <span className="text-1-span">{"Approval Pending with " + (data.authenticator_name ? data.authenticator_name : '-')}</span>
                                </div>
                                <div className="text-2-div">
                                    <span className="text-2-span">{"Once approved you will be able to download"}</span>
                                </div>
                                {
                                    data.overseers.length !== 0 &&
                                    <div className="overseers-download-div">
                                        <div className="overseers-download-label">
                                            <img src={`${USER}`} className="user-icon"
                                                 alt="SaralShare"/>
                                            <span className="overseer-name-label-span">Overseers -</span>
                                        </div>
                                        {data.overseers.map((item, i) => {
                                            return (<div key={i}
                                                         className="overseers-download-fields">
                                                <p>{i + 1}.</p>
                                                <p>{item[1]}</p>
                                                <p>|</p>
                                                <p>{item[0]}</p>
                                            </div>);
                                        })}
                                    </div>
                                }
                            </div>
                            <button className="inactive-download-button"
                                    disabled={true}
                            >
                                <img src={`${ExpiredDownload}`} className="download_type-icon"
                                     alt="SaralShare"/>
                            </button>
                        </div>
                    ))}
                </>
            )
        }

        if (tabValue === 2) {
            return (
                <>
                    {sharingRequestDetails && sharingRequestDetails.length > 0 && sharingRequestDetails.map(data => (
                        <>
                            {data.status === "declined" ?
                                (
                                    <div className="request-box-decline-div">
                                        <div className="request-box-decline-detail-div" key={data}>
                                            <div className="request-box-decline-heading-div">
                                                <div className="decline-box-detail-div" key={data}>
                                                    <span className="decline-box-detail-span-red">{data.authenticator_name + "  has declined for approval of - " + data.note_title}</span>
                                                </div>
                                            </div>
                                            {
                                                data.overseers.length !== 0 &&
                                                <div className="overseers-download-div">
                                                    <div className="overseers-download-label">
                                                        <img src={`${USER}`} className="user-icon"
                                                             alt="SaralShare"/>
                                                        <span className="overseer-name-label-span">Overseers -</span>
                                                    </div>
                                                    {data.overseers.map((item, i) => {
                                                        return (<div key={i}
                                                                     className="overseers-download-fields">
                                                            <p>{i + 1}.</p>
                                                            <p>{item[1]}</p>
                                                            <p>|</p>
                                                            <p>{item[0]}</p>
                                                        </div>);
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ) : (null)
                            }
                        </>
                    ))}
                </>
            )
        }

        if (tabValue === 3) {
            return (
                <>
                    {sharingRequestDetails && sharingRequestDetails.length > 0 && sharingRequestDetails.map(data => (
                        <>
                            {data.status === "declined" || data.status === "pending" ?
                                (null) : (
                                    <div className="request-box-download-div">
                                        <div className="request-box-detail-div" key={data}>
                                            {!data.is_private || data.status === "approved" ? (
                                                <>
                                                    <div className="file-heading-div">
                                                        <span className="file-heading-span">{data.note_title}</span>
                                                    </div>
                                                    <div className="remaining-time-div">
                                                        <CircularProgress
                                                            variant="determinate"
                                                            disableShrink
                                                            sx={{
                                                                color: '#FF7576',
                                                                [`& .${circularProgressClasses.circle}`]: {
                                                                    strokeLinecap: 'round',
                                                                },
                                                            }}
                                                            size={18}
                                                            thickness={5}
                                                            value={fileExpired(data.created_at) ? 100 : getRemainingTime(data.created_at)}
                                                        />
                                                        <span className="remaining-time-span">{fileExpired(data.created_at) ? 'Expired' : expiredInHours - getTimeDifferences(data.created_at) + ' Hour(s) Remaining'}</span>
                                                    </div>
                                                    {data.status === "approved" ? (
                                                        <>
                                                            <div className="authenticator-detail-div">
                                                                <img src={`${USER}`} className="user-icon"
                                                                     alt="SaralShare"/>
                                                                <div className="authenticator-name-div">
                                                                    <span className="authenticator-name-label-span">Approved By -</span>
                                                                    <span
                                                                        className="authenticator-name-span">{data.authenticator_name ? data.authenticator_name : '-'}</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                data.overseers.length !== 0 &&
                                                                <div className="overseers-download-div">
                                                                    <div className="overseers-download-label">
                                                                        <img src={`${USER}`} className="user-icon"
                                                                             alt="SaralShare"/>
                                                                        <span className="overseer-name-label-span">Overseers -</span>
                                                                    </div>
                                                                    {data.overseers.map((item, i) => {
                                                                        return (<div key={i}
                                                                                     className="overseers-download-fields">
                                                                            <p>{i + 1}.</p>
                                                                            <p>{item[1]}</p>
                                                                            <p>|</p>
                                                                            <p>{item[0]}</p>
                                                                        </div>);
                                                                    })}
                                                                </div>
                                                            }
                                                        </>
                                                    ) : (null)}
                                                </>
                                            ) : (null)
                                            }
                                        </div>
                                        {data.status !== "pending" ?
                                            <button
                                                className={fileExpired(data.created_at) ? "expired-download-button" : "active-download-button"}
                                                disabled={fileExpired(data.created_at)}
                                                onClick={() => downloadFile(data.file_id)}
                                            >
                                                <img
                                                    src={fileExpired(data.created_at) ? `${ExpiredDownload}` : `${ActiveDownload}`}
                                                    className="download_type-icon" alt="SaralShare"/>
                                            </button>
                                            : null
                                        }
                                    </div>
                                )}
                        </>
                    ))}
                </>
            )
        }
    }

    function snackbarError(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarErrorMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setSnackbarErrorMessage('')} severity="warning" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    function snackbarSuccess(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarSuccessMessage('')}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={() => setSnackbarSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    return (
        <div className='sharing-request-div'>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            {showLoader ? <Loader/> : false}
            <div className='sharing-request-main-div'>
                {snackbarError(snackbarErrorMessage)}
                {snackbarSuccess(snackbarSuccessMessage)}
                <div className='sharing-request-row-1-div'>
                    <div className='heading-div'>
                        <img src={`${SaralShare}`} className="heading-icon" alt="SaralShare"/>
                        <span className='sharing-request-heading-span'>Saral Share</span>
                    </div>
                </div>

                <div className='sharing-request-row-2-div'>
                    <div className='sharing-request-row-2-background-div'>
                        <Tabs value={tabValue}
                              onChange={tabChange}
                              TabIndicatorProps={{
                                  style: {
                                      background: '#FF9559',
                                      height: 5,
                                      borderTopLeftRadius: 5,
                                      borderTopRightRadius: 5
                                  }
                              }}
                              sx={{
                                  "&& .Mui-selected": { // && are used to increase the specificity
                                      color: "#FF9559"
                                  }
                              }}
                              variant="scrollable"
                              scrollButtons
                              allowScrollButtonsMobile
                              aria-label="scrollable force tabs example">
                            <Tab label="All"
                                 value={0}
                                 style={styles.tab}
                            />
                            <Tab label="Approvals"
                                 value={1}
                                 style={styles.tab}
                            />
                            <Tab label="Declined"
                                 value={2}
                                 style={styles.tab}
                            />
                            <Tab label="Downloads"
                                 value={3}
                                 style={styles.tab}
                            />
                        </Tabs>
                    </div>
                </div>

                <div className='sharing-request-row-3-div'>
                    {typeOfRequest()}
                </div>
            </div>
        </div>
    )
}