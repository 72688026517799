import React, {createRef, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import SaralShare from "../../../assets/icons/saral-share.svg"
import Upload from "../../../assets/icons/upload.svg"
import Successful from "../../../assets/icons/successful.svg"
import './SharingModule.css';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {Alert, Box, Breadcrumbs, Link, Snackbar, Tab, Tabs, Tooltip, Typography} from "@mui/material";
import {AUTH_STATUS, AUTH_TOKEN, USER_ID, USER_ROLE} from "../SharingConstants";
import Loader from "../loader/Loader";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function SharingModule() {
    let navigate = useNavigate();
    const fileInput = createRef();
    const [fileName, setFileName] = useState('Select file to upload');
    const [googleCloudFileName, setGoogleCloudFileName] = useState('');
    const [file, setFile] = useState('');
    const [filePath, setFilePath] = useState();
    const fileNameChanged = useRef(false);
    const [tabValue, setTabValue] = useState(0);
    const [modalShow, setModalShow] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [visible, setVisible] = React.useState(false);

    const formFields = {
        rName: "", rPhoneNumber: "", rEmail: "", rNoteTitle: "", rRemark: "", aName: "",
        aPhoneNumber: "", aEmail: "", aNoteTitle: "", aRemark: ""
    };
    const [formValues, setFormValues] = useState(formFields);
    const [formErrors, setFormErrors] = useState({});
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
    const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');
    const [overseers, setOverseers] = useState([{name: '', phone_number: ''}]);

    useEffect(() => {
        const authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
        const role = localStorage.getItem(USER_ROLE);
        if (!authStatus) {
            navigate('/', {replace: false})
        }
        if (role !== 'admin') {
            navigate('/dashboard', {replace: false})
        }
    }, []);

    function handleClick(event) {
        event.preventDefault();
        const url = event.currentTarget.href;
        window.location.href = url;
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/dashboard" onClick={handleClick}>
            SARAL
        </Link>,
        <Typography key="2" color="#366FB2" className='sharing-module-breadcrumb-typography'>
            Sharing Module
        </Typography>,
    ];

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let overseersError = false;
        const errors = validate(formValues);
        if (visible) {
            overseersError = overseerError(overseers);
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0 && overseersError === false) {
            sendFile(e).then();
        }
    }

    const validate = (values) => {
        const errors = {}
        const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        const regexPhoneNumber = /^[56789]\d{9}$/;
        if (tabValue === 0 || tabValue === 1) {
            if (!values.rName) {
                errors.rName = "This field is required!"
            }
            if (!values.rPhoneNumber) {
                errors.rPhoneNumber = "This field is required!"
            } else if (values.rPhoneNumber && !regexPhoneNumber.test(values.rPhoneNumber)) {
                errors.rPhoneNumber = "Invalid phone number!"
            }
            if (values.rEmail.length && !regexEmail.test(values.rEmail)) {
                errors.rEmail = "Invalid email!"
            }
            if (!values.rNoteTitle) {
                errors.rNoteTitle = "This field is required!"
            }
            if (!values.rRemark) {
                errors.rRemark = "This field is required!"
            }
        }
        if (tabValue === 1) {
            if (!values.aName) {
                errors.aName = "This field is required!"
            }
            if (!values.aPhoneNumber) {
                errors.aPhoneNumber = "This field is required!"
            } else if (values.aPhoneNumber && !regexPhoneNumber.test(values.aPhoneNumber)) {
                errors.aPhoneNumber = "Invalid phone number!"
            }
            if (values.aEmail.length && !regexEmail.test(values.aEmail)) {
                errors.aEmail = "Invalid email!"
            }
            if (!values.aNoteTitle) {
                errors.aNoteTitle = "This field is required!"
            }
            if (!values.aRemark) {
                errors.aRemark = "This field is required!"
            }
        }
        return errors;
    };
    const overseerError = (values) => {
        if (values[0].phone_number[0] < 5 || values[0].phone_number.length !== 10)
            return true
        for (let i = 0; i < values.length; i++) {
            if (values[i].name === "" || values[i].phone_number === "") {
                return true
            }
        }
        return false
    }

    function uploadFile() {
        setFileName(fileInput.current.files[0].name)
        fileNameChanged.current = true;
        setFile(fileInput.current.files[0]);
    }
    function uploadFileName(event){
        setGoogleCloudFileName(event.target.value);
    }

    async function sendFile(event) {
        event.preventDefault();
        const url = "sharing_module/create";

        if (!file && !googleCloudFileName) {
            setSnackbarErrorMessage('No file chosen');
            return;
        }

        if (file && googleCloudFileName) {
            setSnackbarErrorMessage('file and file name can not be send at a time');
            return;
        }


        const formData = new FormData();
        if(file) {
            formData.append('file', file);
            formData.append('file_name', '')
        }
        if(googleCloudFileName) {
            formData.append('file', '');
            formData.append('file_name', googleCloudFileName)
        }

        if (tabValue === 0 || tabValue === 1) {
            if (!(formValues.rName || formValues.rPhoneNumber || formValues.rNoteTitle || formValues.rRemark)) {
                return;
            } else {
                formData.append('r_name', formValues.rName)
                formData.append('r_phone_number', formValues.rPhoneNumber)
                formData.append('r_email', formValues.rEmail ? formValues.rEmail : '')
                formData.append('r_note_title', formValues.rNoteTitle)
                formData.append('r_remark', formValues.rRemark)
            }
        }
        if (tabValue === 1) {
            if (!(formValues.aName || formValues.aPhoneNumber || formValues.aNoteTitle || formValues.aRemark)) {
                return;
            } else {
                formData.append('a_name', formValues.aName)
                formData.append('a_phone_number', formValues.aPhoneNumber)
                formData.append('a_email', formValues.aEmail ? formValues.aEmail : '')
                formData.append('a_note_title', formValues.aNoteTitle)
                formData.append('a_remark', formValues.aRemark)
                if (overseers[0].name.length) {
                    formData.append('overseers', JSON.stringify(overseers))
                }
            }
        }

        tabValue === 0 ? formData.append('accessiblity', 'public') : formData.append('accessiblity', 'private');

        setShowLoader(true);

        const token = document.querySelector('meta[name="csrf-token"]').content;
        let result = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
            body: formData
        })
        setShowLoader(false);
        result = await result.json();
        if (result.errors === 'User ID missing in token.') {
            localStorage.clear();
            navigate('/', {replace: false})
        }
        if (result.success) {
            setModalShow(true);
            setFilePath(result.file_path);
        } else {
            setSnackbarErrorMessage(result.message);
        }
    }

    function sharingHistory() {
        navigate('/dashboard/sharing_module/sharing_history');
    }

    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleClearOverseers = () => {
        setVisible(!visible);
        setOverseers([{name: '', phone_number: ''}]);
    }
    const handleOverseerInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...overseers];
        list[index][name] = value;
        setOverseers(list);
    }

    const handleRemoveOverseer = (e, index) => {
        const list = [...overseers];
        list.splice(index, 1);
        setOverseers(list);
    }
    const handleAddOverseer = () => {
        setOverseers([...overseers, {name: '', phone_number: ''}]);
    }


    function snackbarError(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarErrorMessage('')}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={() => setSnackbarErrorMessage('')} severity="warning" sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    function snackbarSuccess(message) {
        if (message.length !== 0) {
            return (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbarSuccessMessage('')}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={() => setSnackbarSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            )
        }
    }

    const fileSharedSuccessfully = () => {
        return (
            <div className='file-uploaded-modal-background-div'>
                <div className='modal-body-div'>
                    <div className="successful-icon-div">
                        <img src={`${Successful}`} className="successful-icon" alt="Successful"/>
                    </div>
                    <div className="successful-heading-div">
                        <span className="successful-heading-span">File Shared Successfully</span>
                    </div>
                    <div className="share-link-div">
                        <span className="share-link-span">Share Link-</span>
                    </div>
                    <div className="share-path-div">
                        <Tooltip title={filePath} arrow>
                            <span className="share-path-span">{filePath}</span>
                        </Tooltip>
                        <CopyToClipboard text={filePath} onCopy={() => setSnackbarSuccessMessage('Copied!')}>
                            <ContentCopyRoundedIcon
                                className="copy-button-icon"
                                fontSize="inherit"
                                cursor="pointer"
                                sx={{fontSize: 20}}
                            />
                        </CopyToClipboard>
                    </div>
                    <div className="successful-modal-close-div">
                        <button className="successful-modal-close-button" onClick={() => {
                            setModalShow(false);
                            window.location.reload(false);
                        }}
                        ><span className="successful-modal-close-span">close</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const styles = {
        tab: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 20,
            textTransform: 'none',
            marginLeft: 10
        }
    }

    const numericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) return true
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    return (
        <div className='sharing-module-div'>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            {showLoader ? <Loader/> : false}
            {modalShow ? fileSharedSuccessfully() : false}
            <div className='sharing-module-main-div'>
                <div className='sharing-module-breadcrumb-div'>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}
                                 aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </div>
                {snackbarError(snackbarErrorMessage)}
                {snackbarSuccess(snackbarSuccessMessage)}
                <div className='sharing-module-row-1-div'>
                    <div className='heading-div'>
                        <img src={`${SaralShare}`} className="heading-icon" alt="SaralShare"/>
                        <span className='heading-span'>Saral Share</span>
                    </div>
                    <div className='sharing-history-button-div'>
                        <span className='sharing-history-button-span'
                              onClick={sharingHistory}>See Sharing History</span>
                    </div>
                </div>
                <div className='sharing-module-row-2-div'>
                    <div className='upload-file-heading-div'>
                        <span className='upload-file-heading-span'>Upload File</span>
                    </div>
                    <div className='upload-file-clickable-wrapper-div'>
                        <img src={`${Upload}`} className="upload-icon" alt="SaralShare"/>
                        <input
                            className='upload-file-input'
                            type="file"
                            id='file'
                            ref={fileInput}
                            onChange={uploadFile}
                        />
                        <button className="upload-file-button"
                                onClick={() => {
                                    document.getElementById('file').click()
                                }}>
                            <Tooltip title={fileNameChanged.current ? fileName : ''} arrow>
                                <span className='upload-file-span'>{fileName}</span>
                            </Tooltip>
                        </button>
                    </div>
                    <div className='file_field'>
                        <input
                            className='input-file'
                            type="text"
                            name="file_name"
                            value={googleCloudFileName}
                            onChange={uploadFileName}
                            placeholder="File Name"
                        />
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='sharing-module-row-3-div'>
                        <div className='panel-tab-div'>
                            <Box className='panel-tab-box' sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={tabValue}
                                      onChange={tabChange}
                                      TabIndicatorProps={{
                                          style: {
                                              background: '#FF9559',
                                              height: 5,
                                              borderTopLeftRadius: 5,
                                              borderTopRightRadius: 5
                                          }
                                      }}
                                      sx={{
                                          "&& .Mui-selected": { // && are used to increase the specificity
                                              color: "#FF9559"
                                          }
                                      }}
                                      variant="scrollable"
                                      scrollButtons
                                      allowScrollButtonsMobile
                                      aria-label="scrollable force tabs example">
                                    <Tab label="Share Directly"
                                         value={0}
                                         style={styles.tab}
                                    />
                                    <Tab label="Share with Approval"
                                         value={1}
                                         style={styles.tab}
                                    />
                                </Tabs>
                            </Box>
                        </div>
                        <div className='form-div'>
                            <div className='receiver-div'>
                                <div className='receiver-detail-div'>
                                    <span className='receiver-detail-span'>Receiver's Details</span>
                                </div>
                                <Box>
                                    <div className='text-field-div'>
                                        <span className='text-field-span'>Name*</span>
                                        <input
                                            className='input-field'
                                            type="text"
                                            name="rName"
                                            value={formValues.rName}
                                            onChange={handleChange}
                                            // required
                                        />
                                        <label className='error-label'>{formErrors.rName}</label>
                                    </div>
                                    <div className='text-field-div'>
                                        <span className='text-field-span'>Phone Number*</span>
                                        <input
                                            className='input-field'
                                            type="text"
                                            name="rPhoneNumber"
                                            value={formValues.rPhoneNumber}
                                            maxLength={10}
                                            minLength={10}
                                            onChange={handleChange}
                                            onInput={numericOnly}
                                            // required
                                        />
                                        <label className='error-label'>{formErrors.rPhoneNumber}</label>
                                    </div>
                                    <div className='text-field-div'>
                                        <span className='text-field-span'>Mail Id</span>
                                        <input
                                            className='input-field'
                                            type="text"
                                            name="rEmail"
                                            value={formValues.rEmail}
                                            onChange={handleChange}
                                        />
                                        <label className='error-label'>{formErrors.rEmail}</label>
                                    </div>
                                    <div className='text-field-div'>
                                        <span className='text-field-span'>Note Title*</span>
                                        <input
                                            className='input-field'
                                            type="text"
                                            name="rNoteTitle"
                                            value={formValues.rNoteTitle}
                                            onChange={handleChange}
                                            // required
                                        />
                                        <label className='error-label'>{formErrors.rNoteTitle}</label>
                                    </div>
                                    <div className='text-field-div'>
                                        <span className='text-field-span'>Remark*</span>
                                        <textarea
                                            // className='input-field'
                                            type="text"
                                            name="rRemark"
                                            value={formValues.rRemark}
                                            onChange={handleChange}
                                            rows={5}
                                            style={{fontSize: 22, border: "1px solid black"}}
                                            // required
                                        />
                                        <label className='error-label'>{formErrors.rRemark}</label>
                                    </div>
                                </Box>
                            </div>
                            {tabValue === 1 ? (
                                <>
                                    <div className='divider-div'>
                                        <div className='inner-divider-div'></div>
                                    </div>
                                    <div className='approver-div'>
                                        <div className='approver-detail-div'>
                                            <span className='approver-detail-span'>Approver's Details</span>
                                        </div>
                                        <Box>
                                            <div className='text-field-div'>
                                                <span className='text-field-span'>Name*</span>
                                                <input
                                                    className='input-field'
                                                    type="text"
                                                    name="aName"
                                                    value={formValues.aName}
                                                    onChange={handleChange}
                                                    // required
                                                />
                                                <label className='error-label'>{formErrors.aName}</label>
                                            </div>
                                            <div className='text-field-div'>
                                                <span className='text-field-span'>Phone Number*</span>
                                                <input
                                                    className='input-field'
                                                    type="text"
                                                    name="aPhoneNumber"
                                                    value={formValues.aPhoneNumber}
                                                    maxLength={10}
                                                    minLength={10}
                                                    onChange={handleChange}
                                                    onInput={numericOnly}
                                                    // required
                                                />
                                                <label className='error-label'>{formErrors.aPhoneNumber}</label>
                                            </div>
                                            <div className='text-field-div'>
                                                <span className='text-field-span'>Mail Id</span>
                                                <input
                                                    className='input-field'
                                                    type="text"
                                                    name="aEmail"
                                                    value={formValues.aEmail}
                                                    onChange={handleChange}
                                                />
                                                <label className='error-label'>{formErrors.aEmail}</label>
                                            </div>
                                            <div className='text-field-div'>
                                                <span className='text-field-span'>Note Title*</span>
                                                <input
                                                    className='input-field'
                                                    type="text"
                                                    name="aNoteTitle"
                                                    value={formValues.aNoteTitle}
                                                    onChange={handleChange}
                                                    // required
                                                />
                                                <label className='error-label'>{formErrors.aNoteTitle}</label>
                                            </div>
                                            <div className='text-field-div'>
                                                <span className='text-field-span'>Remark*</span>
                                                <textarea
                                                    // className='input-field'
                                                    type="text"
                                                    name="aRemark"
                                                    row="10"
                                                    value={formValues.aRemark}
                                                    onChange={handleChange}
                                                    rows={5}
                                                    style={{fontSize: 22, border: "1px solid black"}}
                                                    // required
                                                />
                                                <label className='error-label'>{formErrors.aRemark}</label>
                                            </div>
                                        </Box>
                                    </div>
                                    <div style={{margin: "auto"}}>
                                    <div className='overseers-divider-div'>
                                        <div className='overseers-inner-divider-div'></div>
                                    </div>

                                    <div className='overseers-div'>
                                        <div className='overseers-button-div'>
                                            <button type="button" className="btn btn-primary"
                                                    onClick={handleClearOverseers}>
                                                {visible ? 'Remove All' : 'Add Overseers'}</button>
                                        </div>

                                        <Box className={visible ? 'element-visible' : 'element-hidden'}>
                                            {
                                                overseers.map((overseer, i) => (
                                                    <div key={i} className="overseers-fields">
                                                        <div className="overseers-inner-fields-div">
                                                        <div className="overseer-text-field-div">
                                                            <span
                                                                className="overseer-text-field-span">Overseer Name*</span>
                                                            <input type="text" name="name"
                                                                   value={overseer.name || ""}
                                                                   className="form-control"
                                                                   onChange={e => handleOverseerInputChange(e, i)}/>
                                                            {
                                                                overseer.name === "" ?
                                                                    <label className='error-label'> Field is
                                                                        required </label> : (null)
                                                            }
                                                        </div>
                                                        <div className="overseer-text-field-div">
                                                                <span
                                                                    className="overseer-text-field-span">Overseer Phone Number*</span>
                                                            <input type="text" name="phone_number"
                                                                   value={overseer.phone_number || ""}
                                                                   className="form-control"
                                                                   maxLength={10}
                                                                   minLength={10}
                                                                   onInput={numericOnly}
                                                                   onChange={e => handleOverseerInputChange(e, i)}/>
                                                            {
                                                                overseer.phone_number === "" ?
                                                                    <label className='error-label'> Field is
                                                                        required </label> : overseer.phone_number[0] < 5 || overseer.phone_number.length !== 10 ? (
                                                                        <label className='error-label'> Invalid phone
                                                                            number </label>) : (null)
                                                            }
                                                        </div>
                                                        </div>
                                                        <div className="overseer-btn-div">
                                                            <div>
                                                                {
                                                                    overseers.length !== 1 &&
                                                                    <button type="button"
                                                                            className="btn btn-danger"
                                                                            onClick={e => handleRemoveOverseer(e, i)}>Remove</button>
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    overseers[i].name.length !== 0 && overseers[i].phone_number[0] > 4 && overseers[i].phone_number.length > 9 ?
                                                                        (overseers.length - 1 === i &&
                                                                            <button type="button"
                                                                                    className="btn btn-success"
                                                                                    onClick={handleAddOverseer}>Add
                                                                                More</button>) :
                                                                        (overseers.length - 1 === i &&
                                                                            <button type="button"
                                                                                    className="btn btn-success">Add
                                                                                More</button>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Box>
                                    </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='divider-div'></div>
                                    <div className='approver-div'></div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='row-4-div'>
                        <button type="submit" className="btn submit-button mt-3">
                            <span className="submit-span">Submit</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
