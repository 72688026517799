import React from "react";
import './Header.css';
import {AppBar, IconButton, Toolbar} from "@mui/material";
import * as Constant from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import {AUTH_STATUS, AUTH_TOKEN} from "../SharingConstants";
import BJPLogo from "../../../assets/logos/BJP-logo.svg"
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

export default function Header() {
    const navigate = useNavigate();
    const authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
    const authToken = localStorage.getItem(AUTH_TOKEN);

    const logout = function (event) {
        event.preventDefault();
        const url = "/logout";
        const token = document.querySelector('meta[name="csrf-token"]').content;
        fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Network response was not ok.");
            })
            .then(response => {
                {
                    response.success && logoutSuccessful();
                }
            })
            .catch(error => console.log(error.message));
    }

    const logoutSuccessful = () => {
        // localStorage.setItem(Constant.AUTH_STATUS, 'false');
        // localStorage.setItem(Constant.AUTH_TOKEN, '');
        // localStorage.setItem(Constant.USER_ID, '');
        // localStorage.setItem(Constant.USER_ROLE, '');
        // localStorage.setItem(Constant.USER_NAME, '');
        // localStorage.setItem(Constant.USER_EMAIL, '');
        // localStorage.setItem(Constant.USER_PHONE, '');
        localStorage.clear();
        navigate('/', {replace: false})
    };

    const displayDesktop = () => {
        if (authStatus) {
            return (
                <div className="header-div">
                    <Toolbar
                        onClick={() => navigate('/dashboard', {replace: true})}
                    >
                        <img src={`${BJPLogo}`} className="bjp-logo" alt="BJP"/>
                        <span className='bjp-span'>भारतीय जनता पार्टी</span>
                    </Toolbar>
                    <Toolbar>
                        <IconButton
                            aria-label="LogoutRounded"
                            onClick={logout}>
                            <LogoutRoundedIcon fontSize="inherit"/>
                        </IconButton>
                    </Toolbar>
                </div>

            )
        }
    };

    return (
        <header>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            <AppBar className='appbar'>{displayDesktop()}</AppBar>
        </header>
    );
}